define('google-maps-addon/components/google-maps-addon', ['exports', 'ember', 'google-maps-addon/map'], function (exports, _ember, _googleMapsAddonMap) {
  exports['default'] = _ember['default'].Component.extend({
    setupMap: _ember['default'].on('init', function () {
      this.map = new _googleMapsAddonMap['default']();
      this.map.set('owner', this);
    }),

    setupMapElement: _ember['default'].on('didInsertElement', function () {
      var _this = this;

      this.map.initializeOptions();

      // Checking for the availability of Google Maps JavaScript SDK, the hero
      _ember['default'].run.later(function () {
        if (window.google) {
          _this.set('mapElement', _this.map.createMapElement());

          _this.updateMapOptions();
          _this.updateShapes();
        } else {
          console.error('Please include the Google Maps JavaScript SDK.');
        }
      });
    }),

    updateMapOptionsObserver: _ember['default'].observer('mapOptions', function () {
      this.updateMapOptions();
    }),

    updateShapesObserver: _ember['default'].observer('markers', 'circles', 'rectangles', 'polygons', function () {
      _ember['default'].run.once(this, "updateShapes");
    }),

    updateMapOptions: function updateMapOptions() {
      if (this.get('mapElement')) {
        this.map.initializeMouseEventCallbacks();
      }
    },

    updateShapes: function updateShapes() {
      if (this.get('mapElement')) {
        this.map.drawAllShapes();
      }
    }
  });
});