define('google-maps-addon/map', ['exports', 'ember', 'google-maps-addon/mixins/shapes-manager', 'google-maps-addon/mouse-events'], function (exports, _ember, _googleMapsAddonMixinsShapesManager, _googleMapsAddonMouseEvents) {
  exports['default'] = _ember['default'].Object.extend(_googleMapsAddonMixinsShapesManager['default'], {
    /**
    @method initializeOptions
    @usage
      To initialize the `mapOptions`
    **/
    initializeOptions: function initializeOptions() {
      var mapOptions = this.owner.get('mapOptions');
      this.owner.setProperties({
        latitude: mapOptions.latitude || '0',
        longitude: mapOptions.longitude || '0',
        zoom: mapOptions.zoom || 8
      });
    },
    /**
    @method createMapElement
    @usage
      To create a map element using mapOptions
      It creates the map element in the $(div.map-canvas)
    @return map (google map element for other handlings)
    **/
    createMapElement: function createMapElement() {
      var mapOptions = {
        center: new google.maps.LatLng(this.owner.get('latitude'), this.owner.get('longitude')),
        zoom: this.owner.get('zoom'),
        mapTypeId: google.maps.MapTypeId.ROADMAP
      };

      var mapElement = this.owner.$('div.map-canvas')[0];

      this.set("map", new google.maps.Map(mapElement, mapOptions));
      return this.get("map");
    },
    /**
    @method initializeMouseEventCallbacks
    @usage
      To initialize the `mouseevents` to the `mapElement`
    **/
    initializeMouseEventCallbacks: function initializeMouseEventCallbacks() {
      var mapOptions = this.owner.get('mapOptions');
      var mapElement = this.owner.get('mapElement');
      _googleMapsAddonMouseEvents['default'].forEach(function (event) {
        if (mapOptions[event]) {
          if (typeof mapOptions[event] === 'function') {
            google.maps.event.addListener(mapElement, event, mapOptions[event]);
          }
        }
      });
    }
  });
});