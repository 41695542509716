define('google-maps-addon/shapes/info-window', ['exports', 'ember', 'google-maps-addon/shapes/base'], function (exports, _ember, _googleMapsAddonShapesBase) {
  exports['default'] = _googleMapsAddonShapesBase['default'].extend({
    createInstance: function createInstance() {
      return new google.maps.InfoWindow();
    },

    defaultOptions: function defaultOptions() {
      return {};
    },

    normalizeOptions: function normalizeOptions(options) {
      return {
        content: options.content,
        position: new google.maps.LatLng(options.latitude, options.longitude),
        pixelOffset: options.pixelOffset,
        maxWidth: options.maxWidth
      };
    },

    openWindow: _ember['default'].on("create", function () {
      this.get("instance").open(this.get("mapElement"));
    })
  });
});