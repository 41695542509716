define("google-maps-addon/helpers/diff-objects-operations", ["exports"], function (exports) {
  function diffObjects(newObject, oldObject) {
    var diff = {};
    iterateObject(newObject, function (key, value) {
      if (oldObject[key] !== value) {
        diff[key] = value;
      }
    });
    return diff;
  }

  function diffObjectOperations(newObjects, oldObjects) {
    var objectOperations = {
      added: [],
      removed: [],
      updated: []
    };

    var objectsLeft = cloneObject(oldObjects);

    // If old objects are present, figure out which are new and old and removed
    if (oldObjects) {
      iterateObject(newObjects, function (key, attributes) {
        if (oldObjects[key]) {
          var object = oldObjects[key];
          objectOperations.updated.push({ attributes: attributes, object: object });
        } else {
          objectOperations.added.push(attributes);
        }

        delete objectsLeft[key];
      });

      iterateObject(objectsLeft, function (key, object) {
        objectOperations.removed.push({ object: object, key: key });
      });
      // No old objects were present, all are new
    } else {
        iterateObject(objectsLeft, function (key, attributes) {
          objectOperations.push(attributes);
        });
      }

    return objectOperations;
  }

  function iterateObject(object, callback) {
    Object.keys(object).forEach(function (key) {
      callback(key, object[key]);
    });
  }

  function cloneObject(object) {
    var cloned = {};
    iterateObject(object, function (key, value) {
      cloned[key] = value;
    });
    return cloned;
  }

  exports["default"] = diffObjectOperations;
  exports.diffObjects = diffObjects;
});