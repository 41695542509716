define('google-maps-addon/shapes/base', ['exports', 'ember', 'google-maps-addon/mouse-events', 'google-maps-addon/helpers/diff-objects-operations'], function (exports, _ember, _googleMapsAddonMouseEvents, _googleMapsAddonHelpersDiffObjectsOperations) {
  exports['default'] = _ember['default'].Object.extend({
    instance: false,
    mapElement: null,
    options: null,

    createInstance: function createInstance() {
      // Override
    },

    defaultOptions: function defaultOptions() {
      return {};
    },

    normalizeOptions: function normalizeOptions(options) {
      // Override
      return options;
    },

    postCreate: function postCreate() {
      // Override point, optional
    },

    update: function update() {
      if (!this.get('instance')) {
        this.set('instance', this.createInstance());
        _ember['default'].sendEvent(this, "create");
      }

      this.addEvents();

      var mergedOptions = _ember['default'].merge(this.defaultOptions(), this.get('options'));
      var newOptions = this.normalizeOptions(mergedOptions);

      var oldOptions = this.get('oldOptions');
      if (oldOptions) {
        this.setAttributes((0, _googleMapsAddonHelpersDiffObjectsOperations.diffObjects)(newOptions, oldOptions));
      } else {
        this.setAttributes(newOptions);
      }

      this.set('oldOptions', newOptions);
    },

    addToMapWithTimeout: function addToMapWithTimeout() {
      var _this = this;

      _ember['default'].run.later(function () {
        _this.get('instance').setMap(_this.get('mapElement'));
      }, this.get('options.timeout') || null);
    },

    setAttributes: function setAttributes(attributes) {
      this.get('instance').setOptions(attributes);
    },

    addEvents: function addEvents() {
      var options = this.get('options');
      var instance = this.get('instance');

      _googleMapsAddonMouseEvents['default'].forEach(function (eventName) {
        google.maps.event.clearListeners(instance, eventName);

        if (typeof options[eventName] === 'function') {
          google.maps.event.addListener(instance, eventName, options[eventName]);
        }
      });
    },

    remove: function remove() {
      this.get('instance').setMap(null);
    }
  });
});