define('ember-bootstrap/components/bs-navbar/toggle', ['exports', 'ember-bootstrap/components/bs-button', 'ember-bootstrap/templates/components/bs-navbar/toggle'], function (exports, _emberBootstrapComponentsBsButton, _emberBootstrapTemplatesComponentsBsNavbarToggle) {

  /**
   * Component to implement the responsive menu toggle behavior in a [Components.Navbar](Components.Navbar.html)
   * component. Have a look there for examples.
   *
   * @class NavbarToggle
   * @namespace Components
   * @extends Components.Button
   * @public
   */
  exports['default'] = _emberBootstrapComponentsBsButton['default'].extend({
    layout: _emberBootstrapTemplatesComponentsBsNavbarToggle['default'],

    classNames: ['navbar-toggle'],
    classNameBindings: ['collapsed'],
    collapsed: true
  });
});