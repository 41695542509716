define('google-maps-addon/shapes/circle', ['exports', 'google-maps-addon/shapes/base'], function (exports, _googleMapsAddonShapesBase) {
  exports['default'] = _googleMapsAddonShapesBase['default'].extend({
    createInstance: function createInstance() {
      return new google.maps.Circle();
    },

    defaultOptions: function defaultOptions() {
      return {
        fillColor: 'red',
        fillOpacity: 0.5,
        strokeColor: 'red',
        strokeWeight: 2,
        strokeOpacity: 0.6,
        draggable: false,
        editable: false,
        visible: true,
        zIndex: null
      };
    },

    normalizeOptions: function normalizeOptions(options) {
      return {
        center: new google.maps.LatLng(options.latitude, options.longitude),
        fillColor: options.fillColor,
        fillOpacity: options.fillOpacity,
        strokeColor: options.strokeColor,
        strokeWeight: options.strokeWeight,
        strokeOpacity: options.strokeOpacity,
        radius: options.radius,
        draggable: options.draggable,
        editable: options.editable,
        visible: options.visible,
        zIndex: options.zIndex
      };
    }
  });
});