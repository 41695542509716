define('google-maps-addon/mixins/shapes-manager', ['exports', 'ember', 'google-maps-addon/helpers/diff-objects-operations', 'google-maps-addon/helpers/assert-key-exists', 'google-maps-addon/shapes/marker', 'google-maps-addon/shapes/circle', 'google-maps-addon/shapes/rectangle', 'google-maps-addon/shapes/info-window'], function (exports, _ember, _googleMapsAddonHelpersDiffObjectsOperations, _googleMapsAddonHelpersAssertKeyExists, _googleMapsAddonShapesMarker, _googleMapsAddonShapesCircle, _googleMapsAddonShapesRectangle, _googleMapsAddonShapesInfoWindow) {

  var SHAPE_CLASS_MAP = {
    "markers": _googleMapsAddonShapesMarker['default'],
    "circles": _googleMapsAddonShapesCircle['default'],
    "rectangles": _googleMapsAddonShapesRectangle['default'],
    "infoWindows": _googleMapsAddonShapesInfoWindow['default']
  };

  exports['default'] = _ember['default'].Mixin.create({
    setupShapes: _ember['default'].on("init", function () {
      this.set("shapes", {
        markers: {},
        circles: {},
        rectangles: {},
        infoWindows: {}
      });
    }),

    forEachShapeType: function forEachShapeType(callback) {
      var shapes = this.get("shapes");

      Object.keys(shapes).forEach(function (key) {
        callback(key, shapes[key]);
      });
    },

    drawAllShapes: function drawAllShapes() {
      var _this = this;

      this.forEachShapeType(function (typeName, oldShapes) {
        _this.drawShapeForType(typeName, oldShapes, SHAPE_CLASS_MAP[typeName]);
      });
    },

    drawShapeForType: function drawShapeForType(typeName, shapes, ShapeClass) {
      var newShapeOptions = this.owner.get(typeName) || [];
      (0, _googleMapsAddonHelpersAssertKeyExists['default'])(newShapeOptions, typeName);

      var newShapesObject = {};
      newShapeOptions.forEach(function (shape) {
        newShapesObject[shape.key] = shape;
      });

      var mapElement = this.get('map');

      var shapeOperations = (0, _googleMapsAddonHelpersDiffObjectsOperations['default'])(newShapesObject, shapes);

      shapeOperations.added.forEach(function (attributes) {
        var shape = new ShapeClass();
        shape.set('options', attributes);
        shape.set('mapElement', mapElement);

        shape.update();
        shape.addToMapWithTimeout();

        shapes[attributes.key] = shape;
      });

      shapeOperations.updated.forEach(function (operation) {
        operation.object.set('options', operation.attributes);
        operation.object.update();
      });

      shapeOperations.removed.forEach(function (operation) {
        operation.object.remove();
        delete shapes[operation.key];
      });
    }
  });
});